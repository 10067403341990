// store.js
import {createStore} from 'vuex';
import userModule from './common/user';

export default createStore({
    state: {
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        user: userModule
    }
});
