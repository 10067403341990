import axios from 'axios'
import { ElMessage } from 'element-plus'

// 创建axios实例
const service = axios.create({
    baseURL: 'http://localhost:9090', // 设置baseURL地址
    timeout: 10000, // 配置请求超时时间
    headers: {
        'Content-Type': 'application/json;charset=UTF-8' // 定义统一的请求头
    }
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem('token')
        if (token) {
            config.headers.Authorization = token // 配置请求头
        }
        return config
    },
    error => {
        console.error(error)
        Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.status === 0) {
            return res
        } else {
            ElMessage.error(res.message || '网络异常')
            return Promise.reject(res)
        }
    },
    error => {
        console.error(error)
        ElMessage.error('网络异常')
        return Promise.reject(error)
    }
)

export default service
