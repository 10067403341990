import loginApi from '@/api/system/login'

export default {
    namespaced: true,
    state() {
        return {
            token: '', // 用户 token
            userInfo: null // 用户对象初始为空
        }
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
            if(sessionStorage.getItem("token") ==null){
                sessionStorage.setItem("token", token);
            }
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        clearToken(state){
            state.token = "";
            sessionStorage.removeItem("token");
        }
    },
    actions: {
        login({ commit }, { username, password }) {
            return loginApi( { username, password })
                .then(response => {
                    const { token, userInfo } = response.data;
                    commit('setToken', token);
                    commit('setUserInfo', userInfo);
                    return response; // 返回响应供其他代码使用
                });
        },
        updateToken({commit}, token) {
            commit('setToken', token); // 调用 mutation 更新 token
            if(sessionStorage.getItem("token") ==null){
                sessionStorage.setItem("token", token);
            }
        },
        updateUserInfo({commit}, userInfo) {
            commit('setUserInfo', userInfo); // 调用 mutation 更新用户对象
        }
    },
    getters: {
        getToken: state => state.token, // 获取 token 的 getter
        getUserInfo: state => state.userInfo // 获取用户对象的 getter
    }
}
