import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        redirect: window.innerWidth < 768? '/mobile/home':'/pc/home', // 确保重定向路径正确

    },
    {
        path: '/mobile',
        name: 'mobile',
        redirect: '/mobile/home', // 确保重定向路径正确
        children: [
            {
                path: 'login', // 子路由不以 '/' 开始
                name: 'mobile-login',
                meta: {
                    title: '欢迎登录'
                },
                component: () => import('../views/mobile/login.vue')
            },
            {
                path: 'home', // 子路由不以 '/' 开始
                name: 'mobile-home',
                component: () => import('../views/mobile/home.vue'),
                children:[
                    {
                        path: '', // 空路径，即默认子路由
                        name: 'home-default',
                        meta: {
                            title: '首页'
                        },
                        component: () => import('../views/pc/page/index.vue')
                    },
                ]
            }
        ]
    },
    {
        path: '/pc',
        name: 'pc',
        redirect: '/pc/home', // 确保重定向路径正确
        children: [
            {
                path: 'login', // 子路由不以 '/' 开始
                name: 'pc-login',
                meta: {
                    title: '欢迎登录'
                },
                component: () => import('../views/pc/login.vue')
            },
            {
                path: 'home', // 子路由不以 '/' 开始
                name: 'pc-home',
                component: () => import('../views/pc/home.vue'),
                children:[
                    {
                        path: '', // 空路径，即默认子路由
                        name: 'home-default',
                        meta: {
                            title: '首页'
                        },
                        component: () => import('../views/pc/page/index.vue')
                        // redirect:'pc/home/index'
                    },
                    {
                        path: 'index', // 子路由不以 '/' 开始
                        name: 'pc-index',
                        meta: {
                            title: '首页'
                        },
                        component: () => import('../views/pc/page/index.vue')
                    },
                    {
                        path: 'user', // 子路由不以 '/' 开始
                        name: 'pc-user',
                        meta: {
                            title: '个人主页'
                        },
                        component: () => import('../views/pc/page/user.vue')
                    },
                    {
                        path: 'articles', // 子路由不以 '/' 开始
                        name: 'pc-articles',
                        meta: {
                            title: '文章页'
                        },
                        component: () => import('../views/pc/page/articles.vue')
                    },
                    {
                        path: 'resources', // 子路由不以 '/' 开始
                        name: 'pc-resources',
                        meta: {
                            title: '资源页'
                        },
                        component: () => import('../views/pc/page/resources.vue')
                    },
                ]
            },

        ]
    },
    // 通配符路由使用带有自定义正则表达式的参数进行定义
    {
        path: '/:catchAll(.*)',
        component:()=>import('../views/404.vue')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// 添加全局前置守卫
router.beforeEach((to, from, next) => {
    const isMobileUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const isWindows = /Windows/i.test(navigator.userAgent);
    const hasTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    const isNarrowScreen = window.innerWidth < 768;

    // 综合判断是否应该被视为移动设备
    const isMobile = isMobileUserAgent || hasTouch || (!isWindows && isNarrowScreen);

    // 获取目标路由的标题
    if (to.meta.title != null) {
        const pageTitle = to.meta.title;
        // 设置选项卡的标题
        document.title = pageTitle;
    }

    // 根据是否是移动设备来决定重定向的路径
    if (isMobile && to.path.startsWith('/pc')) {
        next(to.path.replace('/pc', '/mobile'));
    } else if (!isMobile && to.path.startsWith('/mobile')) {
        next(to.path.replace('/mobile', '/pc'));
    } else {
        // 否则，正常导航
        next();
    }
});

export default router
